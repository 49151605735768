import React from "react"
import Link from "gatsby-link"
import SEO from "../components/seo"
import Layout from "../components/layout"
import NavLink from "../components/nav-link"

const CaseStudies = ({ pageContext, location }) => {
  const { group, index, first, last /*_pageCount*/ } = pageContext
  const previousUrl = index - 1 === 1 ? "/" : (index - 1).toString()
  const nextUrl = (index + 1).toString()
  const basePath = "/case_studies"
  return (
    <Layout location={location}>
      <SEO title="Case Studies"></SEO>
      {/* Case Studies */}
      <section className="case-studies">
        <div className="container">
          <div className="row row--center">
            <div className="col col__11">
              <h1 className="title title--center">Case Studies</h1>
              {group.map(({ node }) => (
                <article key={node.slug} className="case-box">
                  <Link to={node.path}>
                    <figure className="case-box__image">
                      <img src={node.acf.featured_image.sizes.medium} alt="" />
                    </figure>
                    <div className="case-box__data">
                      <h2 className="case-box__title">{node.title}</h2>
                      <p className="case-box__description">
                        {node.acf.the_solution}
                      </p>
                    </div>
                  </Link>
                </article>
              ))}
            </div>
          </div>
          <div className="row">
            <ul className="pagination">
              <li className="pagination__item current">
                <NavLink
                  basePath={basePath}
                  test={first}
                  url={previousUrl}
                  text="< Go to Previous Page"
                />
              </li>
              <li className="pagination__item">
                <NavLink
                  basePath={basePath}
                  test={last}
                  url={nextUrl}
                  text="Go to Next Page >"
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CaseStudies
